.custom-datepicker {
    font-size: 16px;
    padding: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-family: 'Montseratt', sans-serif;
    width: 100%;
    box-sizing: border-box;
}